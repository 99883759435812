import React, { Component } from 'react';
import { Helmet } from 'react-helmet' ;
import Layout from '../layouts/home'
import '../css/blog.scss'
import SEO from '../components/seo'

class BlogTemplate extends Component {

  render() {
    const post = this.props.data.markdownRemark
    const url = `http://www.ceremonynepal.com/blogs${post.fields.slug}`;

    return <Layout>
      <SEO
        title={post.frontmatter.title}
        keywords={['weddings', 'events', 'tips', 'guides']}
        description={post.frontmatter.summary}
        meta={[
          {
            property: 'og:url',
            content: url
          },
          {
            property: 'og:image',
            content: `http://www.ceremonynepal.com${post.frontmatter.featuredImage.childImageSharp.sizes.src}`
          }
        ]}
      />
      <section class="reserve-block dark-bg" style={{ position: 'relative' }}>
        <div class="blog-hero" style={{ backgroundImage: `url(${post.frontmatter.featuredImage.childImageSharp.sizes.src})` }}></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="blog-title-container">
                <h5 class="blog-title">{post.frontmatter.title}</h5>
                <div>{post.frontmatter.date}</div>
                <div>By: {post.frontmatter.author}</div>
                <div class="blog-fb-like-wrapper">
                  <iframe
                    src={`https://www.facebook.com/plugins/like.php?href=${url}&width=124&layout=button_count&action=like&size=small&show_faces=true&share=true&height=46&appId=2070588019696815`}
                    width="450"
                    height="80"
                    style={{ border: "none", overflow: "hidden" }}
                    scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="reserve-block">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div dangerouslySetInnerHTML={{ __html: post.html }} class="justify-content-center blog-wrapper" />
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section class="reserve-block text-center">
        <a href="/blogs" class="btn btn-danger">See More Posts Like this</a>
      </section>
      <div id="fb-root"></div>
      <Helmet>
        <script async defer src="//assets.pinterest.com/js/pinit.js"></script>
      </Helmet> 
    </Layout>
  }
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($Slug: String!) {
    markdownRemark(fields: { slug: { eq: $Slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        summary
        featuredImage {
          childImageSharp{
            sizes(maxWidth: 1200) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
